// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */
import styled from 'styled-components'
import React, { useState, useRef, useEffect } from 'react';

// Wrapper for the entire layout
const LayoutWrapper = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 5px;
    margin: 0 auto;
    background: #2c2c2c;
    border: 4px solid #ddc5b5;
    border-radius: 12px;
    opacity: 1;
    font-family: 'Cooper Black', sans-serif; /* Use Cooper Black font */
    height: 40%;
    /* Landscape mode: Adjusting font size */
    @media only screen and (max-height: 900px) and (orientation: landscape) {
        width: 50%;
        top: 2%;
        height: 80%;
    }

    /* Portrait mode: Adjusting font size */
    @media only screen and (orientation: portrait) {
     width: 30%;
         
    }
`;

// Header style
const Header = styled.div`
    height: 12%;
    margin: 2px; 
    text-align: center;
    font-size:28px;
    font-weight: bold;
    padding: 2px;
    background-color: #ddc5b5;
    border-bottom: 1px solid #ccc;
    @media only screen and (max-height: 900px) and (orientation: landscape) {
        font-size: 18px;
    }
`;

// Wrapper for the menu layout
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  height: 100%;
`;

// Column for the main menu
const MainMenu = styled.div`
    justify-content: center;
    align-items: center; 
    background-color: #ddc5b5;
    padding: 20px;
    display: flex;
    width: 100%;
    gap: 3px;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

// Column for the submenu
const SubMenu = styled.div`
    background-color: #c4e4fc;
    padding: 20px;
    display: flex;
    gap: 3px;
    height:100%;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

// Button style for the menu items
const MenuItemButton = styled.div<{ active: boolean }>`
  align-items: center; 
  background-color: #bf4453;
  color: white;
  border: none;
  padding: 2px;
  cursor: pointer;
  font-size: 2vw;
  border-radius: 4px;
  &:hover {
    background-color: #89303a;
  }
  /* Show submenu when active */
  ${({ active }) =>
        active &&
        `
    background-color: #89303a;
  `}
  @media (max-width: 900px) {
    width: 20%;
    height: 40%;
  }
`;

// Submenu button style
const SubMenuButton = styled.button`
    background-color: #2196f3;
     color: white;
    border: none;
    padding: 2px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    height: 20%;
  &:hover {
    background-color: #1e88e5;
  }

  @media (max-width: 900px) {
    font-size: 12px;
    width: 20%;
    height: 100%;
  }
`;
interface IQueueOptions {

    closeFunction: any,

}

export default function ServiceViewBox(data: IQueueOptions) {
    //const ServiceViewBox = () => {
    const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
    const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null);
    const [hoveredMenu, setHoveredMenu] = useState<string | null>(null);

    const menuRef = useRef<HTMLDivElement>(null);
    const subMenuRef = useRef<HTMLDivElement>(null);

    // Submenu options for each main menu
    const subMenus: Record<string, string[]> = {
        Fighter: ['Change Name', 'Change Gang', 'Change Lucky #'],
        City: ['Buildings', 'Plots', 'Mayor'],
        Mint: ['Bit Fighters', 'Buildings', '1k Card'],
        Bonus: ['Daily Prize', 'Roll the Dice'],
    };

    // Function to handle submenu button click
    const handleSubMenuClick = (menu: string, subMenu: string) => {
        //console.log(`${menu} -> ${subMenu} clicked`);
        setSelectedSubMenu(subMenu);
    };
    const handleMouseEnter = (menu: string) => {
        setHoveredMenu(menu);
    };
    const handleMouseLeave = () => {
        setHoveredMenu(null);
    };

    const handleMenuClick = (menu: string) => {
        if (selectedMenu === menu) {
            setSelectedMenu(null);
            setSelectedSubMenu(null);
        } else {
            setSelectedMenu(menu);
            setSelectedSubMenu(null); // Reset submenu selection
        }
    };

    const handleBackToSubMenu = () => {
        setSelectedSubMenu(null);
    };


    // Submenu content based on the selected or hovered menu
    const renderSubMenuContent = () => {
        // Show submenu based on either selectedMenu or hoveredMenu
        const menuToDisplay = selectedMenu || hoveredMenu;

        if (!menuToDisplay || !subMenus[menuToDisplay]) {
            return <p>Hello!<br /> Make a selection to begin!</p>;
        }
        if (selectedSubMenu) {
            return (
                <>
                    {/* <p>You selected: {selectedSubMenu}</p>
                    <BackButton onClick={handleBackToSubMenu}>Back</BackButton> */}
                </>
            );
        }
        return subMenus[menuToDisplay].map((subMenu) => (
            <SubMenuButton key={subMenu} onClick={() => handleSubMenuClick(menuToDisplay, subMenu)}>
                {subMenu}
            </SubMenuButton>
        ));
    };

    // Handle clicks outside the menu
    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node) &&
            subMenuRef.current && !subMenuRef.current.contains(event.target as Node)) {
            setSelectedMenu(null);
            setSelectedSubMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <LayoutWrapper>
            {/* Header */}
            <Header>Welcome to Service!</Header>

            <SubMenu ref={subMenuRef}>
                {renderSubMenuContent()}
            </SubMenu>
            <MenuWrapper>
                <MainMenu ref={menuRef}>
                    {Object.keys(subMenus).map((menu) => (
                        <MenuItemButton
                            key={menu}
                            active={selectedMenu === menu || hoveredMenu === menu}
                            onClick={() => handleMenuClick(menu)}
                            onMouseEnter={() => handleMouseEnter(menu)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {menu}
                        </MenuItemButton>
                    ))}
                </MainMenu>
            </MenuWrapper>
        </LayoutWrapper>
    );
}

