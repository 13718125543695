

// export const random_spawn_points = [
//   // {
//   //   x: 600,
//   //   y: 400
//   // }, 
//   // {
//   //   x: 700,
//   //   y: 400
//   // },
//   // {
//   //   x: 800,
//   //   y: 400
//   // },
//   // {
//   //   x: 900,
//   //   y: 400
//   // },
//   // {
//   //   x: 960,
//   //   y: 400
//   // },
//   // {
//   //   x: 1020,
//   //   y: 500
//   // },
//   // {
//   //   x: 1020,
//   //   y: 550
//   // },
//   // {
//   //   x: 1120,
//   //   y: 600
//   // },

//   // {
//   //   x: 1020,
//   //   y: 620
//   // }, 
//   // {
//   //   x: 900,
//   //   y: 620
//   // },
//   // {
//   //   x: 800,
//   //   y: 620
//   // },
//   // {
//   //   x: 600,
//   //   y: 620
//   // },
//   // {
//   //   x: 550,
//   //   y: 620
//   // },
//   {
//     x: 500,
//     y: 500
//   },


//   // {
//   //   x: 550,
//   //   y: 400
//   // },
// ]

const random_spawn_points_player = []
for (let i = 900; i < 1000; i = i + 10) {
  for (let j = 470; j < 480; j = j + 5) {
    random_spawn_points_player.push({
      // x:i,
      // y:j
      x: 500,
      y: 500
    })
  }
}



export const random_spawn_points = random_spawn_points_player;